@import 'layout-custom';
@import 'flex-x-attribute';

body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  margin-bottom: 0 !important;
}

// 提供 1 - 100 的margin简写
@for $i from 1 to 100 {
  .m-t-#{$i} {
    margin-top: $i * 1px;
  }
  .m-b-#{$i} {
    margin-bottom: $i * 1px;
  }
  .m-l-#{$i} {
    margin-left: $i * 1px;
  }
  .m-r-#{$i} {
    margin-right: $i * 1px;
  }
}

// ant样式调整
.ant-btn + .ant-btn {
  margin-left: 8px;
}
.tree-transfer {
  .ant-btn + .ant-btn {
    margin: 0;
  }
}
.ant-input-number {
  width: 100% !important;
}

// 筛选查询
.common-query {
  padding: 24px 12px 0 2px;
  .ant-row,
  .ant-select,
  .ant-picker {
    width: 100%;
  }
  .ant-form-item {
    margin-bottom: 24px !important;
  }
  .ant-form-item-label {
    width: 110px;
  }
  .ant-form-item-control {
    margin-right: 20px;
  }
  .ant-form-item-control-input-content {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .ant-select-selection-item {
    white-space: unset;
  }
}

// 表格列表
.common-list {
  padding: 16px 32px 24px;
  .list-header {
    margin-bottom: 16px;
    .header-title {
      font-size: 16px;
      font-weight: bold;
      color: #000;
      line-height: 24px;
    }
    .header-operate {
      > span + span {
        margin-left: -8px;
      }
      .ant-upload-select {
        margin: 0 8px;
      }
      .refresh {
        margin: 0 16px 0 24px;
        opacity: 0.65;
        cursor: pointer;
      }
    }
  }
  .list-multiple {
    margin-bottom: 16px;
    padding: 0 16px;
    height: 40px;
    background: #e6f3ff;
    border: 1px solid #badeff;
    .select-color {
      color: #0082ff;
    }
    .select-num {
      margin-left: 10px;
    }
    .ant-btn {
      padding: 0 0 0 8px;
    }
  }
}
.list-table {
  .table-status {
    .circle {
      width: 6px;
      height: 6px;
      margin-right: 8px;
      border-radius: 50%;
      &.grey {
        background: rgba(0, 0, 0, 0.25);
      }
      &.green {
        background: #52c41a;
      }
    }
    .iconfont {
      font-size: 20px;
      margin-right: 8px;
    }
  }
  .ant-btn {
    padding: 0;
  }
  .ant-btn > span {
    white-space: pre-wrap;
    text-align: left;
  }
  // 表格多级展示时子级间距调整
  .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
    margin: 4px 0 4px 40px !important;
  }
  // 隐藏表头各列之前的线
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }
  // 分页和表格之间间距调整
  .ant-table-pagination.ant-pagination {
    margin: 16px 0 0 0;
  }
  // 合计栏样式调整
  .ant-table-summary > tr > th,
  .ant-table-summary > tr > td {
    font-weight: bold;
    background: #fafafa;
  }

  &.editable {
    .ant-table-tbody tr {
      vertical-align: baseline;
    }
  }
}

// 详情表单
.common-details {
  padding: 32px;
  .ant-upload {
    overflow: auto;
  }
  .ant-descriptions {
    padding: 32px 0 16px;
    border-bottom: 1px solid #e8e8e8;
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
}
.multi-details,
.horizontal-details {
  .details-title {
    padding: 16px 32px;
    font-size: 16px;
    font-weight: bold;
    color: #19191a;
    line-height: 24px;
    border-bottom: 1px solid #e6e9f1;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .details-info {
    padding: 24px 32px;
  }
  .details-btn {
    padding: 12px 32px;
    margin: 0 24px;
    background: #fff;
    border-radius: 2px;
  }
}
.horizontal-details {
  .ant-select,
  .ant-picker {
    width: 100%;
  }
  .ant-form-item-label {
    width: 94px;
  }
  .details-form {
    padding: 24px 0 8px 28px;
  }
}

// 新增编辑
.common-setting {
  &-title {
    font-size: 16px;
    font-weight: bold;
    color: #19191a;
    line-height: 24px;
    padding-bottom: 16px;
    border-bottom: 1px solid #e6e9f1;
  }
  &-content {
    margin: 24px 0 40px;
  }
  &-buttons {
    margin: 8px 14px 0;
  }
}

// 底部按钮
.common-buttons {
  margin: 24px 24px 0;
  padding: 0 22px;
  height: 56px;
  background: #fff;
}
