.classify {
  .ant-tabs-nav {
    background: #fff;
    padding: 4px 32px 0;
    margin: 0;
  }
}

.standard-name {
  max-height: 466px;
  overflow-y: scroll;
  .snames-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -28px;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.45);
  }
  .snames-btn:hover {
    color: rgba(0, 0, 0, 0.65);
  }
}
