.edit-erp {
  &-product {
    margin-right: 12px;
    color: rgba(0, 0, 0, 0.65);
    .discount {
      margin-top: 24px;
      &-title {
        margin-left: 10px;
      }
      &-input {
        width: 80px;
        margin-bottom: 0;
      }
      &-btn {
        margin-right: -16px;
      }
    }
    .mode {
      margin: 16px 0;
      &-title {
        margin-right: 4px;
      }
      &-radio {
        margin: 0 -16px 0 0;
      }
    }
    .amount {
      &-title {
        font-weight: bold;
        margin-right: 4px;
      }
      &-total {
        font-size: 24px;
        font-weight: bold;
      }
    }
  }
}

.detail-erp {
  position: relative;
  &-logo {
    position: absolute;
    top: 0;
    right: 0;
    line-height: 1;
    .iconfont {
      color: rgba(0, 0, 0, 0.2);
      font-size: 100px;
    }
  }
  &-card {
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
    .ant-descriptions {
      padding: 0 0 4px !important;
      margin-bottom: 16px;
      .ant-descriptions-row > th,
      .ant-descriptions-row > td {
        padding-bottom: 12px;
      }
    }
  }
}

.print-erp {
  .ant-modal-body {
    padding: 24px 24px 10px;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    border-bottom: none;
  }

  &-title {
    width: 35%;
    height: 32px;
    font-size: 18px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    display: block;
    margin: auto;
    text-align: center;
    border-top: none;
    border-left: none;
    border-right: none;
  }
  &-info {
    margin: 10px 0;
    .ant-descriptions-row > th,
    .ant-descriptions-row > td {
      padding-bottom: 4px;
    }

    .edit-input {
      height: 22px;
      color: rgba(0, 0, 0, 0.65);
      border-top: none;
      border-left: none;
      border-right: none;
    }
  }
  &-total {
    width: 100%;
    height: 38px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    background: #fafafa;
    .col1 {
      margin-left: 10px;
    }
    .col2 {
      text-align: right;
      flex: 1;
    }
    .col3 {
      margin: 0 30px 0 80px;
    }
  }
  &-name {
    font-weight: bold;
    color: #000;
    text-align: center;
    margin-top: -10px;
  }
}

// 打印时设置特殊样式
@media print {
  .print-erp {
    width: 100% !important;
    max-width: 100% !important;
    padding-bottom: 0 !important;
    vertical-align: top !important;
    .ant-table-body {
      overflow: auto !important;
      max-height: fit-content !important;
    }
    .ant-modal-footer {
      display: none;
    }

    &-title {
      width: 100%;
    }

    .ant-table-thead > tr > th,
    .print-erp-total {
      -webkit-print-color-adjust: exact;
    }
  }
}
