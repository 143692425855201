.vin {
  .vin-search {
    padding: 20px 32px;
    background: #fff;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
    .search-input,
    .search-btn {
      width: 100%;
      min-width: fit-content;
      height: 48px;
    }
  }
  .vin-list {
    margin: 24px 32px;
    .list-tabs,
    .list-view {
      min-width: fit-content;
      min-height: 484px;
      background: #fff;
      border-radius: 4px;
      border: 1px solid #f3f3f3;
    }
    .list-tabs {
      padding: 24px 0;
      .total {
        color: #acacb3;
        line-height: 22px;
        padding: 0 24px 16px;
      }
      .tabs {
        max-height: 370px;
        overflow-y: scroll;
        > div {
          line-height: 20px;
          color: #19191a;
          padding: 10px 24px;
          margin-bottom: 10px;
          cursor: pointer;
          &:hover {
            color: #0082ff;
          }
        }
        > div.active {
          font-weight: bold;
          color: #0082ff;
          background: rgba(228, 240, 252, 0.8);
          position: relative;
          &::after {
            position: absolute;
            top: 0;
            right: 0;
            display: block;
            content: '';
            width: 2px;
            height: 100%;
            background: #0082ff;
          }
        }
      }
    }
    .list-view {
      padding: 24px;
      .logo {
        margin-top: 42px;
      }
      .infos {
        margin-left: 24px;
        .infos-title {
          font-size: 20px;
          font-weight: 600;
          line-height: 28px;
          margin-bottom: 24px;
        }
      }
    }
  }
  .details {
    .details-left,
    .details-right {
      .left-row {
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 0 !important;
        }
        .row-title {
          width: 126px;
          line-height: 22px;
          color: rgba(0, 0, 0, 0.45);
        }
        .row-text {
          width: 180px;
          line-height: 22px;
        }
      }
    }
    .details-left {
      position: relative;
      margin-right: 32px;
      &::after {
        position: absolute;
        top: 0;
        right: -8px;
        display: block;
        content: '';
        width: 1px;
        height: 100%;
        background: #e6e9f1;
        margin: 3px 0;
      }
    }
  }
}
