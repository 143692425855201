.assembly {
  .add-product {
    margin: -8px 32px 32px;
    &-btn {
      margin-bottom: 24px;
    }
    &-list {
      padding-bottom: 24px;
      .header {
        margin-bottom: 10px;
        .header-name {
          font-weight: bold;
          color: rgba(0, 0, 0, 0.85);
        }
      }
      .card {
        .ant-descriptions {
          padding: 0 0 4px !important;
          margin-bottom: 16px;
          border-bottom: 1px solid #e9e9e9;
          .ant-descriptions-row > th,
          .ant-descriptions-row > td {
            padding-bottom: 12px;
          }
        }
        .card-add {
          margin-top: 16px;
        }
        .card-input {
          .input {
            width: 80px !important;
          }
          .tip {
            color: rgba(0, 0, 0, 0.65);
            margin: 0 16px;
          }
        }
      }
    }
  }
  .fee-total {
    font-weight: bold;
    color: rgba(0, 0, 0, 0.65);
    margin-right: 30px;
  }
}
