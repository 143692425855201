.error {
  height: calc(100% - 80px);
  margin: 40px 48px;
  background: #fff;
  border-radius: 2px;
  .error-img {
    width: 438px;
  }
  .error-info {
    margin-left: 80px;
    .info-title {
      font-size: 24px;
      font-weight: 600;
      color: #303133;
      line-height: 33px;
      letter-spacing: 2px;
      margin-bottom: 16px;
      .title-icon {
        width: 24px;
        height: 24px;
      }
    }
    .info-desc {
      font-size: 14px;
      color: #8f939a;
      line-height: 28px;
      margin-left: 14px;
      position: relative;
      &::before {
        position: absolute;
        top: 11px;
        left: -13px;
        display: block;
        content: '';
        width: 6px;
        height: 6px;
        background: #c4c8cc;
        border-radius: 50%;
      }
    }
    .info-btn {
      margin: 24px 0;
      width: 88px;
    }
  }
}
