// SiderCustom
.sider-custom {
  .sider-logo {
    padding: 20px 0;
    img {
      height: 24px;
      display: block;
      &.shrink {
        margin: auto;
      }
      &.lay {
        margin-left: 24px;
      }
    }
  }
  .iconfont {
    font-size: 18px !important;
  }
}

// HeaderCustom
.header-custom {
  height: 48px !important;
  background: #fff !important;
  box-shadow: 0 1px 4px 0 rgba(0, 21, 41, 0.12);
  padding: 0 24px !important;
  z-index: 9;
  .header-btn {
    padding: 0 0 0 8px;
  }
}

// BreadcrumbCustom
.breadcrumb-custom {
  width: 100%;
  padding: 16px 32px;
  background: #fff;
  .breadcrumb-item {
    font-size: 20px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    line-height: 28px;
    margin-top: 16px;
  }
}

// PageCustom
.page-custom {
  margin: 24px;
  background: #fff;
  border-radius: 2px;
}
