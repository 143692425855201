.login {
  width: 100%;
  min-width: 400px;
  height: 100%;
  min-height: 400px;
  background: url('../../assets/images/login.png') no-repeat center;
  background-size: cover;
  .login-logo {
    width: 184px;
    height: 48px;
    margin-bottom: 52px;
  }
  .login-form {
    width: 368px;
    .ant-input-affix-wrapper {
      height: 40px;
    }
    .login-form-icon {
      color: #0082ff;
    }
    .login-form-button {
      width: 100%;
      height: 40px;
    }
  }
  .login-slider {
    position: relative;
    width: 368px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    color: #fff;
    background: #d6dbe0;
    border-radius: 2px;
    .slider-bg {
      height: 40px;
      width: 0;
      border-radius: 2px 0 0 2px;
      background: #0082ff;
    }
    .slider-text {
      position: absolute;
      top: 0;
      width: 100%;
      text-align: center;
      user-select: none;
    }
    .slider-img {
      position: absolute;
      top: -3px;
      left: -3px;
      width: 45px;
      height: 45px;
      cursor: pointer;
    }
  }
}
