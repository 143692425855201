// 列表页
.goods-files-query {
  padding: 8px 32px !important;
  .files-row {
    width: 100%;
    padding-top: 16px;
    border-bottom: 1px dashed #e9e9e9;
    &:last-child {
      border-bottom: none;
    }
    p {
      width: 80px;
      min-width: 80px;
      line-height: 32px;
    }

    .ant-row {
      margin-bottom: -8px;
    }
  }
}

// 详情页
.goods-files-detail {
  .ant-tabs-nav,
  .ant-tabs-content-holder {
    padding: 0 32px;
    background-color: #fff;
  }
  .ant-tabs-content-holder {
    margin: 8px 24px 16px;
  }

  .detail-tabpanel {
    .ant-descriptions {
      padding: 32px 0 16px;
      border-bottom: 1px solid #e8e8e8;
      &:last-child {
        border-bottom: none;
      }
    }

    &-price {
      .ant-descriptions-item-content {
        display: block;
      }
    }

    &-photo {
      .ant-descriptions-item-label {
        margin-bottom: -8px;
      }
      .ant-descriptions-item-content {
        margin-bottom: 16px;
      }
    }

    &-space {
      width: calc(100% + 64px);
      height: 26px;
      background: #f0f2f5;
      margin: -1px -32px;
    }

    &-link {
      font-size: 14px;
      font-weight: 400;
      margin-left: 24px;
      text-decoration: underline;
    }
  }
}

// 新增编辑页
.goods-files-edit {
  padding: 24px 0;
  &-step {
    padding: 0 24px;
    min-width: 0;
    height: calc(100vh - 248px);
    overflow-x: hidden;
    overflow-y: scroll;

    // 编辑图片/视频
    .step-edit-photo {
      .ant-select {
        width: 240px;
      }

      .ant-upload {
        color: rgba(0, 0, 0, 0.65);
      }
      .ant-upload-picture-card-wrapper {
        width: fit-content;
      }
      .ant-upload.ant-upload-select-picture-card {
        width: 240px;
        height: 180px;
        border-radius: 2px;
        border: 1px dashed rgba(0, 0, 0, 0.15);
        overflow: hidden;
      }
    }
  }

  .ant-menu-item-selected::after {
    opacity: 1;
    transform: scaleY(1);
  }
}

.ant-form-item-explain-custom-error {
  color: #ff4d4f;
}
