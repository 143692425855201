@charset "utf-8";

.flex-x {
  display: flex;
  align-items: center;
  justify-content: center;

  /* 主轴方向 */
  &.m-l-r {
    flex-direction: row;
  }

  &.m-r-l {
    flex-direction: row-reverse;
  }

  &.m-t-b {
    flex-direction: column;
  }

  &.m-b-t {
    flex-direction: column-reverse;
  }

  /* 容器行内内容块主轴方向上的空间分配 */
  &.m-s-start {
    justify-content: flex-start;
  }

  &.m-s-end {
    justify-content: flex-end;
  }

  &.m-s-center {
    justify-content: center;
  }

  &.m-s-around {
    justify-content: space-around;
  }

  &.m-s-between {
    justify-content: space-between;
  }

  &.m-s-evenly {
    justify-content: space-evenly;
  }

  &.m-s-average {
    > * {
      width: 10px;
      flex: auto;
    }
  }

  /* 容器行内内容块交叉轴方向上的空间分配 */
  &.a-s-start {
    align-items: flex-start;
  }

  &.a-s-end {
    align-items: flex-end;
  }

  &.a-s-center {
    align-items: center;
  }

  &.a-s-baseline {
    align-items: baseline;
  }

  &.a-s-stretch {
    align-items: stretch;
  }

  .start {
    align-self: flex-start;
  }

  .end {
    align-self: flex-end;
  }

  .center {
    align-self: center;
  }

  .baseline {
    align-self: baseline;
  }

  .stretch {
    align-self: stretch;
  }

  .minauto {
    flex: 0 0 auto;
  }

  .maxauto {
    flex: auto;
  }

  /* 多行容器以及交叉轴方向 */
  &.a-t-b,
  &.a-l-r {
    flex-wrap: wrap;
  }

  &.a-b-t,
  &.a-r-l {
    flex-wrap: wrap-reverse;
  }

  /* 容器行空间分配 */
  &.r-s-start {
    align-content: flex-start;
  }

  &.r-s-end {
    align-content: flex-end;
  }

  &.r-s-center {
    align-content: center;
  }

  &.r-s-around {
    align-content: space-around;
  }

  &.r-s-between {
    align-content: space-between;
  }

  &.r-s-evenly {
    align-content: space-evenly;
  }

  &.r-s-stretch {
    align-content: stretch;
  }
}
