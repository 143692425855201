.welcome {
  width: 100%;
  height: 100%;
  .img {
    width: 340px;
    height: 262px;
  }
  .text {
    font-size: 16px;
    color: #000;
    line-height: 22px;
    margin-top: 30px;
  }
}
